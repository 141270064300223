import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
// layout constants
import { LayoutTypes } from '../constants/layout';

// strore
import { RootState } from '../redux/store';

// All layouts containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import TwoColumnLayout from '../layouts/TwoColumn/';

import {
    authProtectedFlattenRoutes,
    publicProtectedFlattenRoutes,
    authPartialProtectedFlattenRoutes,
} from './index';
import ClientsContext from '../pages/dashboard/Location/Context/ClientsContext';
import { Client } from '../pages/dashboard/Clients/interfaces/client.interfaces';
import { getAllCLients } from '../helpers/api/apiPortalData';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '../pages/dashboard/ToastMessage/ToastErrorMessage';

interface RoutesProps {}

const Routes = (props: RoutesProps) => {
    const { layout } = useSelector((state: RootState) => ({
        layout: state.Layout,
    }));
    const [clientsData, setClientsData] = useState<Client[]>([]);
    const [selectedClient, setSelectedClient] = useState<Client>({} as Client);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const failed = showErrorToast(t('Loading clients failed'));

    const setClients = (clients: Client[], resetClients?: boolean) => {
        if (resetClients) {
            setClientsData([]);
        }

        setClientsData(clients);
    };

    const setCurrentClient = (client: Client, resetClient?: boolean) => {
        if (resetClient) {
            setSelectedClient({} as Client);
        }

        setSelectedClient(client);
    };

    const getClients = useCallback(async () => {
        setLoading(true);
        try {
            let res = await getAllCLients();
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setClients(res.data as Client[]);
                    setLoading(false);
                }
            } else failed();
        } catch (err) {
            console.error(err);
            failed();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getClients();
        // eslint-disable-next-line
    }, []);

    const getLayout = () => {
        let layoutCls = TwoColumnLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_VERTICAL:
                layoutCls = VerticalLayout;
                break;
            default:
                layoutCls = TwoColumnLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    return (
        <BrowserRouter>
            <Switch>
                <Route path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
                    <DefaultLayout {...props} layout={layout}>
                        <Switch>
                            {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </DefaultLayout>
                </Route>

                <Route path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
                    <Layout {...props}>
                        <Switch>
                            {authProtectedFlattenRoutes.map((route: any, index: number) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </Layout>
                </Route>

                <ClientsContext.Provider
                    value={{
                        clients: clientsData,
                        selectedClient,
                        setClient: setCurrentClient,
                        setClients,
                    }}
                >
                    {!loading ? (
                        <Route path={authPartialProtectedFlattenRoutes.map((r: any) => r['path'])}>
                            <Layout {...props}>
                                <Switch>
                                    {authPartialProtectedFlattenRoutes.map(
                                        (route: any, index: number) => {
                                            return (
                                                !route.children && (
                                                    <route.route
                                                        key={index}
                                                        path={route.path}
                                                        roles={route.roles}
                                                        exact={route.exact}
                                                        component={route.component}
                                                    />
                                                )
                                            );
                                        },
                                    )}
                                </Switch>
                            </Layout>
                        </Route>
                    ) : (
                        <Loader />
                    )}
                </ClientsContext.Provider>
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
