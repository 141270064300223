import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: {
    username: string;
    password: string;
    client_id: string;
    grant_type: string;
}) {
    const baseUrl = '/connect/token';
    let data = new URLSearchParams(params).toString();
    const config = {
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    return api.create(`${baseUrl}`, data, config);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/api/email/resetPassword/';
    return api.create(`${baseUrl}`, params);
}

function resetPassword(params: { newPassword: string; token: string; id: string }) {
    const baseUrl = '/api/Account/users/resetPassword/' + params.id;
    params.token = decodeURIComponent(params.token.replace(/ /g, '+'));
    return api.update(`${baseUrl}`, params);
}

function validateToken(params: { token: string; userId: string }) {
    const baseUrl = 'api/Account/users/validateResetPassToken';
    return api.create(`${baseUrl}`, {
        token: decodeURIComponent(params.token.replace(/ /g, '+')),
        userId: params.userId,
    });
}

function confirmUser(params: { token: string; userId: string }) {
    const baseUrl = 'api/Account/users/confirmUser/' + params.userId;
    return api.create(`${baseUrl}`, {
        token: decodeURIComponent(params.token.replace(/ /g, '+')),
        userId: params.userId,
    });
}

export { login, logout, signup, forgotPassword, resetPassword, validateToken, confirmUser };
