import { createContext } from 'react';
import { Client } from '../../Clients/interfaces/client.interfaces';

interface IClientsContext {
    clients: Client[];
    selectedClient: Client;
    setClients: (clients: Client[], resetClients?: boolean) => void;
    setClient: (client: Client, resetClient?: boolean) => void;
}

const ClientsContext = createContext<IClientsContext>({
    clients: [] as Client[],
    selectedClient: {} as Client,
    setClients: (clients: Client[]) => {},
    setClient: (client: Client) => {},
});

export default ClientsContext;
