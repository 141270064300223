import { createContext } from 'react';
import { Location } from '../../../../helpers/api/apiPortalData';

interface ILocationsContext {
    locations: Location[];
    setLocations: (locations: Location[], resetLocations?: boolean) => void;
}

const LocationsContext = createContext<ILocationsContext>({
    locations: [] as Location[],
    setLocations: (locations: Location[]) => {},
});

export default LocationsContext;
