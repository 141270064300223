import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore, setAuthorization, decodeJWTtoken } from '../../helpers/api/apiCore';

// helpers
import {
    login as loginApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    resetPassword as resetPasswordApi,
} from '../../helpers/';

// actions
import { authApiResponseSuccess, authApiResponseError } from './actions';

// constants
import { AuthActionTypes } from './constants';

interface UserData {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
}

interface PasswordResetData {
    payload: {
        id: string;
        token: string;
        newPassword: string;
    };
    type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
    try {
        const client_id = 'sensware_spa';
        const grant_type = 'password';
        const response = yield call(loginApi, { username, password, client_id, grant_type });

        const { access_token, refresh_token, token_type, expires_in } = response.data;
        const { user, authorization } = decodeJWTtoken({
            access_token,
            refresh_token,
            token_type,
            expires_in,
        });

        api.setLoggedInUser(user, authorization);
        setAuthorization(authorization.access_token);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
        //Remove if there were any previous errors
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, ''));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        // yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* resetPassword({ payload: { newPassword, token, id } }: PasswordResetData): SagaIterator {
    try {
        const response = yield call(resetPasswordApi, { newPassword, token, id });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, resetPassword);
}

export function* watchLogoutLogin(): any {
    yield takeEvery(AuthActionTypes.RESET, logout);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchLogoutLogin),
        fork(watchResetPassword),
    ]);
}

export default authSaga;
