import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { APICore } from '../helpers/api/apiCore';

// actions
import { logoutUser } from '../redux/actions';

// store
import { AppDispatch, RootState } from '../redux/store';

const Root = () => {
    const api = new APICore();
    const { userLoggedIn } = useSelector((state: RootState) => ({
        userLoggedIn: state.Auth.userLoggedIn,
    }));

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        //Če je uporabnik neavtenticiran sproži akcijo da ga odjavi
        if (!api.isUserAuthenticated() && userLoggedIn) dispatch(logoutUser());
        // eslint-disable-next-line
    }, [dispatch, userLoggedIn]);

    const getRootUrl = () => {
        let url: string = 'landing';

        if (api.isUserAuthenticated() === false) url = 'auth/login';
        else if (api.isUserAdministrator()) url = `clients`;
        else {
            if (api.getLoggedInUser().clientCode && api.getLoggedInUser().clientCode !== undefined)
                url = `${api.getLoggedInUser().clientCode}/locations/map`;
            else url = 'clientNotFound';
        }
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
